<template>
    <div class="InterviewTab-container">
        <!--发布方式TAB按钮：...-->
        <div class="way-tab">
            <!-- 发布方式 -->
            <div class="wt-label fl">{{ $t('myProject.releaseMode') }}</div>
            <!-- 我的访谈 -->
            <div class="wt-area fl">
                <div :class="{ active: tabIndex === 0 }" @click="changeTab(0)" class="wt-btn">

                    {{ $t("myProject.all") }}
                </div>
                <div :class="{ active: tabIndex === 1 }" @click="changeTab(1)" class="wt-btn">
                    <div class="wt-btn-badge" v-if="unRead.publisherTotal>0">{{ unRead.publisherTotal<10 ?
                        unRead.publisherTotal : "10+" }}
                    </div>


                    {{ $t("myProject.init3Minute") }}
                </div>
                <div :class="{ active: tabIndex === 2 }" @click="changeTab(2)" class="wt-btn">
                    <div class="wt-btn-badge" v-if="unRead.inviteeTotal>0">{{ unRead.inviteeTotal<10 ?
                        unRead.inviteeTotal : "10+" }}
                    </div>


                    {{ $t("myProject.receive3Minute") }}
                </div>
                <div :class="{ active: tabIndex === 3 }" @click="changeTab(3)" class="wt-btn">
                    <div class="wt-btn-badge" v-if="unRead.privacyTotal>0">{{ unRead.privacyTotal<10 ?
                        unRead.privacyTotal : "10+" }}
                    </div>

                    {{ $t("myProject.vipPrivateRecommendation3Minute") }}
                </div>
                <div :class="{ active: tabIndex === 4 }" @click="changeTab(4)" class="wt-btn">
                    <div class="wt-btn-badge" v-if="unRead.multiInviteTotal>0">{{ unRead.multiInviteTotal<10 ?
                        unRead.multiInviteTotal : "10+" }}
                    </div>

                    {{ $t("myProject.multiPersonCommunication") }}
                </div>
            </div>
        </div>
        <!--表格(我的访谈)-->
        <Table2 :list="list" ref="Table2"/>
        <div style="text-align: center;padding-top: 20px" v-if="list.length==0">{{$t('myWallet.noDataAvailable')}}
            <!--暂无数据--></div>
        <el-pagination
                :current-page.sync="pageNum"
                :page-size="pageSize"
                :total="total"
                @current-change="handleCurrentChange"
                layout="prev, pager, next,slot"
                style="text-align: center"
                v-if="total !== 0"
        >
            <slot>
                <div class="jump-page">
                    <!-- 前往 -->
                    <span>{{ $t("myProject.goTo") }}</span>
                    <input type="text" v-model.number="goPageNum"/>
                    <i @click="goJumpPage(goPageNum)" class="el-icon-arrow-right"></i>
                </div>
            </slot>
        </el-pagination>
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
    </div>
</template>

<script>
    import Table2 from "@/components/my_project/Table2";
    import {myInterviewList, selectInterviewStatistics, selectStatisticsForType} from "@/api/myProjectApi";
    import {pageMixin} from "@/mixins";
    import Loading from "@/components/loading";

    export default {
        name: "InterviewTab",
        mixins: [pageMixin],
        data() {
            return {
                tabIndex: 0,
                isLoading: false,
                unRead: {
                    inviteeTotal: 0,
                    publisherTotal: 0,
                    multiInviteTotal: 0,
                    privacyTotal: 0,
                },
            };
        },
        components: {
            Table2,
            Loading,
        },
        created() {
            this.getDataList();
            this.selectInterviewStatistics();
        },
        methods: {
            changeTab(index) {
                this.tabIndex = index;
                this.pageNum = 1;
                this.goPageNum = 1;
                this.getDataList();
            },
            goJumpPage(num) {
                this.pageNum = num;
                this.getDataList();
            },
            //请求数据列表（目列表）
            async getDataList() {
                let formData = {
                    dataType: this.tabIndex,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    token: localStorage.getItem("token"),
                };
                this.isLoading = true;
                let res = await myInterviewList(formData);
                this.isLoading = false;
                if (res.code == 200) {
                    this.total = res.data?.total ?? 0;
                    this.list = res.data?.list ?? [];
                } else {
                    this.total = res.data?.total ?? 0;
                    this.list = res.data?.list ?? [];
                }
            },
            handleCurrentChange: function handleCurrentChange(p) {
                this.pageNum = p;
                this.getDataList();
            },

            async selectInterviewStatistics() {
                let formData = {
                    token: localStorage.getItem("token"),
                };
                let res = await selectInterviewStatistics(formData);
                if (res.code == 200) {
                    this.unRead = res.data;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .way-tab {
        overflow: hidden;
        background: #fafafa;
        border-top: 1px solid #e5e5e5;
        margin-top: 39px;
    }

    .wt-label {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 47px;
        color: #949494;
        margin: 0 16px;
        display: inline-block;
    }

    .wt-area {
        //overflow: hidden;
    }

    .wt-btn {
        cursor: pointer;
        box-sizing: border-box;
        padding: 0 16px;
        height: 32px;
        background: #ffffff;
        border-radius: 131px;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 32px;
        color: #000000;
        text-align: center;

        margin-right: 16px;
        margin-top: 8px;
        float: left;
        position: relative;

        .wt-btn-badge {
            height: 18px;
            position: absolute;
            top: 0;
            right: 10px;
            background-color: lightslategray;
            line-height: 16px;
            border-radius: 10px;
            transform: translateY(-50%) translateX(100%);
            color: #fff;
            font-size: 12px;

            padding: 0 6px;
            text-align: center;
            white-space: nowrap;
        }
    }

    .active {
        border: 1px solid #707070;
    }

    .jump-page {
        display: inline-block;
        vertical-align: middle;
        width: 124px;
        height: 40px;
        background: #fafafa;
        border-radius: 131px;
        margin-left: 30px;
        line-height: 40px !important;
        text-align: center;
    }

    .jump-page > span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        vertical-align: middle;
    }

    .jump-page > input {
        background: transparent;
        width: 40px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        margin-top: 2px;
    }

    .jump-page > i {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        font-weight: 700;
        font-size: 19px;
    }
</style>
